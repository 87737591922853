import React from "react"
import * as PropTypes from "prop-types"

import { Pagination, Type, LastUpdated, Breadcrum, SEO } from "../components/index"
import * as styles from './token-list-page.module.scss';
import * as setStyles from '../components/token/sets/sets.module.scss';
import { tokenTypeType, pageLinkType } from '../types'

class TokenListPageTemplate extends React.PureComponent {  
  constructor(props) {
    super(props);
    const { 
      pageContext: {
        page,
        pages
      }
    } = props;
    const showIntro = page === 1;
    this.breadcrumPages = [{
      text: 'A-Z Token List',
      to: '/token-list/',
      active: showIntro
    }];
    if(!showIntro) {
      this.breadcrumPages.push(      {
        text: `Page ${page} of ${pages}`,
        to: `/token-list/${page}/`,
        active: true
      })
    }
  }
  render() {
    const {
      pageContext: {
        next, prev,
        page, pages,
        nodes, total,
        limit
      }
    } = this.props;
    let currentLetter = '';
    const showIntro = page === 1;
    const pageName = showIntro ? '/token-list/' : `/token-list/${page}/`;
    return (
      <>
        <SEO
          title={`A-Z List of all ${total} tokens`}
          description={`A comprehensive A-Z list of the ${total} token card types that can be generated in Magic: the Gathering. Including proxies for those tokens not officially printed. This is every token you will need for anything, ever!`}
          keywords={['A-Z', 'list', 'database', 'types']}
          pageUrl={pageName}
        />
        <Breadcrum pages={this.breadcrumPages} />
        {
          showIntro && (
            <header className={styles.header}>
              <h1>MTG Tokens: A-Z List</h1>
              <p>A comprehensive A-Z list of the <span className="big">{total}</span> different token card types that can be generated in Magic: the Gathering <em>(MTG)</em> trading card game. (A token is a permanent that is not represented by a regular card with a casting cost).</p>
              <p>This list includes <span className={setStyles.proxy}>PROXY</span> token cards for the tokens not officially printed by Wizards of the Coast.</p>
              <p>Browse the properties of each token card, the source MTG cards that create the token, the expansion sets that the token has been printed in and all the artwork variations.</p>
              <LastUpdated />
            </header>
          )
        }
        {
          nodes.map((token)=>{
            const { 
              id, name
            } = token.node;
            const firstLetter = name.charAt(0)
            const showLetter = firstLetter !== currentLetter;
            if(showLetter) {
              currentLetter = firstLetter;
            }
            return (
              <React.Fragment key={id}>
                {
                  showLetter && (
                    <div className={styles.letter}>{firstLetter}</div>
                  )
                }
                <Type token={token.node} />
              </React.Fragment>
            )
          })
        }
        <Pagination
          prev={prev} next={next}
          page={page} pages={pages}
          total={total} basePath="/token-list"
          perPage={limit}
        />
      </>
    )
  }
}

TokenListPageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        token: PropTypes.shape({
          ...tokenTypeType
        })
      }),
    })),
    total: PropTypes.number.isRequired,
    ...pageLinkType,
  }).isRequired
}

export default TokenListPageTemplate
